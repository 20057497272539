<template>
    <div>
        <Row :gutter="8" class="m-b-5">
            <i-col span="3">
                <div class="p-l-10 p-r-10 workplatform-radio-button"  v-if="isAuth('settlement_transaction_view')" @click="handleChangeTag(1)">交易</div>
                <div class="p-l-10 p-r-10 workplatform-radio-button-active" >运维</div>
                <div class="p-l-10 p-r-10 workplatform-radio-button" v-if="isAuth('performance_view')" @click="handleChangeTag(3)">收款</div>
            </i-col>
            <i-col span="2">
                <Select size="small" v-model="billStatus" placeholder="结算状态">
                    <Option v-for="item in confirmStatus" :key="item.id" :value="item.id">{{ item.name }}</Option>
                </Select>
            </i-col>
            <i-col span="3">
                <DatePicker v-model="startDate" type="date" size="small" placeholder="起始日期"></DatePicker>
            </i-col>
            <i-col span="3">
                <DatePicker v-model="endDate" type="date" size="small" placeholder="结束日期"></DatePicker>
            </i-col>
            <i-col span="3">
                <Select v-model="currentsettlementCompanyId" size="small"  placeholder="结算公司" clearable>
                  <Option v-for="(company,index) in settlementcompanyArray" :key="index" :value="company.companyId">{{company.companyName}}</Option>
                </Select>
            </i-col>
            <i-col span="3">
                <Select v-model="currentsupplierCompanyId"  size="small" clearable  placeholder="选择供应商" >
                  <Option v-for="(company,index) in suppliercompanyArray" :key="index" :value="company.companyId">{{company.companyName}}</Option>
                </Select>
            </i-col>
            <i-col span="3">
                <i-input v-model="keyword" size="small" clearable placeholder="如：编码、备注"></i-input>
            </i-col>
            <i-col span="4">
                <Button type="primary" size="small" icon="ios-search" :loading="loadSearch" @click="searchmaintenanceSettlementList">
                    <span v-if="!loadSearch">搜索</span>
                    <span v-else>加载中...</span>
                </Button>
                <Button type="success" size="small" :disabled="!allowCreate" class="m-l-5" @click="createNewSettlement"  v-if="isAuth('settlement_maintenance_create')">新建结算</Button>
                <Button type="success" size="small" :disabled="!allowCreate" class="m-l-5" @click="showDownLoadProgrammeModal = true">下载明细</Button>
            </i-col>
        </Row>

        <createMaintanceSettlement ref="createMaintanceSettlement"></createMaintanceSettlement>
        <maintenanceData ref="maintenanceData"></maintenanceData>
        <Modal v-model="showDownLoadProgrammeModal">
          <h3 class="p-b-10" slot="header">请选择下载类型</h3>
          <Row>
            <i-col span="24">
              <RadioGroup v-model="downLoadProgramme" vertical>
                <Radio label="1">
                    <span>全部下载</span>
                </Radio>
                <Radio label="2">
                    <span>勾选下载</span>
                </Radio>
            </RadioGroup>
            </i-col>
          </Row>

          <div slot="footer">
            <Button type="text"  @click="showDownLoadProgrammeModal=false">取消</Button>
            <Button type="primary"  @click="choiceDownLoadProgramme">确认</Button>
          </div>
        </Modal>
    </div>
</template>

<script>
import createMaintanceSettlement from './createMaintenanceSettlement'
import maintenanceData from './maintenanceData'

import { getsettlements, getsuppliers } from '@/api/msp/settlement'
import { ParseDate, ParseDateForMonth } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'

export default {
  components: {
    createMaintanceSettlement, maintenanceData
  },
  data () {
    return {
      loadSearch: false,
      confirmStatus: [
        { id: -1, name: '全部状态' },
        { id: 0, name: '草稿' },
        { id: 4, name: '待审批' },
        { id: 5, name: '已通过' },
        { id: 6, name: '已拒绝' }
      ],
      billStatus: -1,
      startDate: '',
      endDate: '',
      keyword: '',
      allowCreate: false,
      settlementcompanyArray: [],
      suppliercompanyArray: [],
      currentsupplierCompanyId: 0,
      currentsettlementCompanyId: 0,
      showDownLoadProgrammeModal: false,
      downLoadProgramme: '1'
    }
  },
  created () {
    this.initsettlementCompanyArray()
  },
  methods: {
    createNewSettlement () {
      this.$refs.createMaintanceSettlement.showModal(this.settlementcompanyArray, this.currentsettlementCompanyId, this.suppliercompanyArray, this.currentsupplierCompanyId)
    },
    // 获取结算方公司列表
    initsettlementCompanyArray () {
      getsettlements({ }).then(res => {
        this.settlementcompanyArray = res
        this.currentsettlementCompanyId = this.maintenanceSettlementQuery.purchaserCompanyId || null
        this.inisupplierCompanyArray()
      })
    },
    // 获取供应商公司列表
    inisupplierCompanyArray () {
      getsuppliers({ }).then(res => {
        this.suppliercompanyArray = res
        this.currentsupplierCompanyId = this.maintenanceSettlementQuery.supplierCompanyId || null
        this.allowCreate = true
        this.searchmaintenanceSettlementList()
      })
    },
    handleChangeTag (tag) {
      let moduleType = ''

      switch (tag) {
        case 1:
          moduleType = 'transaction' // 交易
          break
        case 2:
          moduleType = 'maintenance' // 运维
          break
        case 3:
          moduleType = 'performance' // 运维
          break
        default:
          break
      }
      this.$store.commit('set_settlement_moduleType', moduleType)
    },
    searchmaintenanceSettlementList () {
      const query = {
        purchaserCompanyId: this.currentsettlementCompanyId,
        supplierCompanyId: this.currentsupplierCompanyId,
        startDate: this.startDate ? ParseDate(this.startDate) : null,
        endDate: this.endDate ? ParseDate(this.endDate) : null,
        billStatus: this.billStatus === -1 ? null : this.billStatus,
        keyword: this.keyword
      }

      this.$store.commit('set_settlement_maintenanceSettlementQuery', query)
      this.$store.commit('set_settlement_feeitemIds', [])
      this.$store.commit('set_settlement_update', new Date())
    },
    choiceDownLoadProgramme () {
      const that = this
      const params = {}
      if (parseInt(that.downLoadProgramme) === 1) {
        params.supplierCompanyId = that.maintenanceSettlementQuery.supplierCompanyId
        params.purchaserCompanyId = that.maintenanceSettlementQuery.purchaserCompanyId
        params.startDate = that.maintenanceSettlementQuery.startDate
        params.endDate = that.maintenanceSettlementQuery.endDate
        params.keyword = that.maintenanceSettlementQuery.keyword
        params.billStatus = that.maintenanceSettlementQuery.billStatus === -1 ? null : that.maintenanceSettlementQuery.billStatus
        params.billType = 2
        params.publisherId = that.$store.getters.token.userInfo.publisherId
      } else {
        if (!that.feeitemIds.length) {
          that.$Notice.info({ desc: '请选择要下载的条目！' })
          return
        } else {
          params.billIdArrJsonStr = JSON.stringify(that.feeitemIds)
          params.billType = 2
        }
      }

      that.downLoadExecl(params)
    },
    downLoadExecl (params) {
      let dateStr = ParseDate(new Date())
      // 全部下载
      if (parseInt(this.downLoadProgramme) === 1) {
        if (params.startDate && params.endDate) {
          dateStr = ParseDateForMonth(params.startDate) + '至' + ParseDateForMonth(params.endDate)
        }
      }
      const filename = '运维结算单(' + dateStr + ').xlsx'
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v2/bill/exportbills', params, filename)
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.token.userInfo.companyId
    },
    maintenanceSettlementQuery () {
      return this.$store.state.settlement.maintenanceSettlementQuery
    },
    feeitemIds () {
      return this.$store.state.settlement.feeitemIds || []
    }
  }
}
</script>
